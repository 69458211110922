<template>
  <div>
    <!-- pic59B4B19A6856A0C9ABA3BE848183DE8E.jpg -->
    <img class="img-large rounded" src="@/assets/news-network/challenge-build-brickkeeper-a-racecar.jpg">
    <p>
      This just in from Professor Brickkeeper:
    </p>
    <p>
      <i>The Maelstrom continues spreading chaos across my Universe!</i>
    </p>
    <p>
      <i>Moments ago, I was zooming around my favorite racetrack, when--- BAM!! Stromlings everywhere! The dark creatures nearly smashed me to bits! And my poor car may never look the same!</i>
    </p>
    <p>
      <i>My fellow Minifigs and I must have our motor racing fun!We need your imagination to push back the Maelstrom!</i>
    </p>
    <p>
      Bolt to the
      <router-link :to="{ name: 'creation-lab-list', params: {challengeId: '4255cf17-bb3e-45d6-b872-3ee60b3abe2d'} }">Creation Lab</router-link>
      to build a custom racecar and unlock the special bonus content!
    </p>
  </div>
</template>
